import cloneDeep from 'lodash/cloneDeep';

import { locationsActionType } from './Locations.type';

/** @namespace Ennero/Store/Locations/Locations/Reducer/getInitialState */
export const getInitialState = () => ({
    isLoaded: false,
    locations: [],
    lastFetchTime: null,
    isLocationsOverlayLoading: false,
    selectedLocation: {},
    isFirstTime: true,
    reportData: {},
    isInitialLoad: true,
    isUpdateTankLoading: false,
});

/** @namespace Ennero/Store/Locations/Locations/Reducer/LocationsReducer */
export const LocationsReducer = (state = getInitialState(), action) => {
    switch (action.type) {
        case locationsActionType.GET_LOCATIONS_DATA: {
            const { locations } = action;
            const { isFirstTime } = state;
            const selectedLocation = isFirstTime
                ? locations[0]
                : state.selectedLocation;

            return {
                ...state,
                locations,
                selectedLocation,
                isLoaded: true,
                isFirstTime: false,
            };
        }

        case locationsActionType.GENERATE_LOCATION_REPORT: {
            const { payload } = action;

            return {
                ...state,
                reportData: payload,
                isInitialLoad: false,
            };
        }

        case locationsActionType.CLEAR_LOCATION_REPORT: {
            return {
                ...state,
                reportData: {},
            };
        }

        case locationsActionType.RESET_LOCATION_REPORT: {
            return {
                ...state,
                isInitialLoad: true,
                reportData: {},
            };
        }

        case locationsActionType.UPDATE_LOCATIONS_OVERLAY_LOADING: {
            const { isLocationsOverlayLoading } = action;

            return {
                ...state,
                isLocationsOverlayLoading,
            };
        }

        case locationsActionType.SELECT_LOCATION: {
            const { selectedLocation } = action;

            return {
                ...state,
                selectedLocation,
            };
        }

        case locationsActionType.RESET_LOCATIONS_STATE: {
            return {
                ...state,
                ...getInitialState(),
            };
        }

        case locationsActionType.UPDATE_TANK_FOR_LOCATION: {
            const {
                payload: { tank_id, enable_auto_fill, percentage_level },
            } = action;
            const updatedLocations = cloneDeep(state.locations);
            const updateSelectedLocation = updatedLocations?.find(
                ({ location_id }) =>
                    location_id === state.selectedLocation?.location_id
            );

            if (updateSelectedLocation) {
                const tank = updateSelectedLocation.tanks.find(
                    (t) => t.tank_id === tank_id
                );

                if (tank) {
                    // Update the tank's properties
                    tank.enable_auto_fill = enable_auto_fill;
                    tank.percentage_level = percentage_level;
                }
            }

            return {
                ...state,
                isUpdateTankLoading: false,
                locations: updatedLocations,
                selectedLocation: updateSelectedLocation,
            };
        }

        case locationsActionType.LOCATION_TANK_STATUS: {
            const { payload } = action;
            const updatedLocations = cloneDeep(state.locations);
            const updateSelectedLocation = updatedLocations?.find(
                ({ location_id }) =>
                    location_id === state.selectedLocation?.location_id
            );

            if (updateSelectedLocation && updateSelectedLocation.tanks) {
                payload.tanks.forEach((statusUpdate) => {
                    const tank = updateSelectedLocation.tanks.find(
                        ({ tank_id }) =>
                            `${tank_id}` === `${statusUpdate.tank_id}`
                    );

                    if (tank) {
                        tank.status = statusUpdate.status;
                    }
                });
            }

            return {
                ...state,
                locations: updatedLocations,
                selectedLocation: updateSelectedLocation,
            };
        }

        case locationsActionType.UPDATE_TANK_LOADING: {
            const { isUpdateTankLoading } = action;

            return {
                ...state,
                isUpdateTankLoading,
            };
        }

        default:
            return state;
    }
};

export default LocationsReducer;

export const locationsActionType = {
    GET_LOCATIONS_DATA: 'GET_LOCATIONS_DATA',
    GENERATE_LOCATION_REPORT: 'GENERATE_LOCATION_REPORT',
    CLEAR_LOCATION_REPORT: 'CLEAR_LOCATION_REPORT',
    RESET_LOCATION_REPORT: 'RESET_LOCATION_REPORT',
    UPDATE_LOCATIONS_OVERLAY_LOADING: 'UPDATE_LOCATIONS_OVERLAY_LOADING',
    SELECT_LOCATION: 'SELECT_LOCATION',
    RESET_LOCATIONS_STATE: 'RESET_LOCATIONS_STATE',
    SET_LOCATION_PAGE_LOADING: 'SET_LOCATION_PAGE_LOADING',
    UPDATE_TANK_FOR_LOCATION: 'UPDATE_TANK_FOR_LOCATION',
    LOCATION_TANK_STATUS: 'LOCATION_TANK_STATUS',
    UPDATE_TANK_LOADING: 'UPDATE_TANK_LOADING',
};
